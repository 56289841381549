@import url(../global/_colors.scss);

html,
body {
  margin: unset;
  height: 100%;
  overflow: overlay;
  overflow-x: hidden;
  background-color: var(--lightGrey);
  font: 400 12px/18px "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.shadow {
  box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
}

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  /* {
    scrollbar-width: auto !important;
    scrollbar-color: #b0b0b0 transparent ;
  }*/

  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b0b0b0;
    border-radius: 10px;
    border: 0px solid #ffffff;
  }
