@use "@angular/material" as mat;
@include mat.core();

$custom-primary: mat.define-palette(mat.$cyan-palette,800);
$custom-accent: mat.define-palette(mat.$cyan-palette,800);
$custom-warn: mat.define-palette(mat.$red-palette, 200);

$theme: mat.define-light-theme((
  color: (
    primary: $custom-primary,
    accent: $custom-accent,
    warn: $custom-warn,
  )
 ));

 $custom-dark-primary: mat.define-palette(mat.$grey-palette,200);
 $custom-dark-accent: mat.define-palette(mat.$grey-palette,200);
 $custom-dark-warn: mat.define-palette(mat.$red-palette, 200);

$dark_theme: mat.define-dark-theme((
  color: (
    primary: $custom-dark-primary,
    accent: $custom-dark-accent,
    warn: $custom-dark-warn,
  )
 ));

@include mat.all-component-themes($theme);

// @include mat.core-theme($theme);
// @include mat.select-theme($theme);
// @include mat.form-field-theme($theme);
// @include mat.checkbox-theme($theme);

.mat-field-dark-theme {
  // @include mat.core-theme($dark_theme);
  // @include mat.form-field-theme($dark_theme);
  // @include mat.form-field-theme($dark_theme);
  // @include mat.checkbox-theme($dark_theme);
  @include mat.all-component-colors($dark_theme);
  color: white;
}




// $achieve-primary: (
//   50: #bac6ff,
//   100: #6f82e4,
//   200: #6478d9,
//   300: #4254ad,
//   400: #36479c,
//   500: #24327b,
//   600: #212e74,
//   700: #1b2769,
//   800: #111b55,
//   900: #0c1447,
//   A100: #716fe4,
//   A200: #6664d9,
//   A400: #3d369c,
//   A700: #2d1b69,
// );

// $achieve-red: (
//   50: #ff7396,
//   100: #eb5278,
//   200: #d43f64,
//   300: #c33357,
//   400: #b1274a,
//   500: #a12041,
//   600: #951d3b,
//   700: #881935,
//   800: #7c152f,
//   900: #6e1028,
//   A100: #eb5299,
//   A200: #d43f84,
//   A400: #b1275c,
//   A700: #881944,
// );

// $primary: mat.mat-color(mat.mat-palette($achieve-primary));
// $accent: mat.mat-color(mat.mat-palette($achieve-primary, A200, A100, A400));
// $error: mat.mat-color(mat.mat-palette($achieve-red));
// $background: mat.map-get(mat.$mat-light-theme-background, 'background');
// $success: mat.mat-color(mat.mat-palette($mat-light-green));


.basic-container {
  padding: 5px;
}

.blue-snackbar {
    // background: $primary;
}

.success-snackbar {
    background: mat.get-color-from-palette(mat.define-palette(mat.$green-palette, 400));
}

.error-snackbar {
    // background: $error;
    background: mat.get-color-from-palette(mat.define-palette(mat.$red-palette, 400));
}


.heading_h3 {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px!important;
  margin-top: 15px!important;
  text-align: justify;
}
